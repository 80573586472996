import request from '@/utils/request'

// 查询【请填写功能名称】列表
export function listWord(query) {
  return request({
    url: '/sensitive/word/list',
    method: 'get',
    params: query
  })
}

// 查询【请填写功能名称】详细
export function getWord(id) {
  return request({
    url: '/sensitive/word/' + id,
    method: 'get'
  })
}

// 新增【请填写功能名称】
export function addWord(data) {
  return request({
    url: '/sensitive/word',
    method: 'post',
    data: data
  })
}

// 修改【请填写功能名称】
export function updateWord(data) {
  return request({
    url: '/sensitive/word',
    method: 'put',
    data: data
  })
}

// 删除【请填写功能名称】
export function delWord(id) {
  return request({
    url: '/sensitive/word/' + id,
    method: 'delete'
  })
}

export function initWord() {
  return request({
    url: '/sensitive/word/init',
    method: 'get',
  })
}

export function testWord(content) {
  return request({
    url: '/sensitive/word/test',
    method: 'put',
    data: content
  })
}
