<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>敏感词测试</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="词组" prop="content" >
        <a-textarea v-model="form.content" placeholder="请输入词组" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { testWord } from '@/api/sensitive/word'

export default {
  name: 'TestForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
          content: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,

        word: null,

        isBlack: null

      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWord(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 删除按钮操作 */
    handleTest (row) {
        var that = this
        const ids = row.id || this.ids
        this.$confirm({
            onOk () {
                return testWord(word)
                    .then(() => {
                        that.onSelectChange([], [])
                        that.getList()
                        that.$message.success(
                            '包含敏感词',
                            3
                        )
                    })
            },
            onCancel () {}
        })
    },
    /** 提交按钮 */
    submitForm: function () {
        testWord(this.form).then(response => {
            this.$message.success(
                '包含敏感词',
                3
            )
            this.open = false
            this.$emit('ok')
        }).finally(() => {
            this.submitLoading = false
        })
    }
  }
}
</script>
